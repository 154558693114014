import React from 'react'
import Logo from '../assets/logo.jpg';
import { FaPlay, FaPhone, FaEnvelope, FaGlobe } from 'react-icons/fa';
import CEO from '../assets/ceo.jpeg';
import Performance from '../assets/performance.jpeg'

const Navbar = () => {
  return (

    <div className='col p-2 text-white'>
    
    <div className='flex items-center pt-8 pl-2 sm:pt-4 md:pt-4 lg:pt-10 md:pl-8'>
    <img className='sm:w-[30px] md:w-[80px] lg:w-[100px] w-[40px] sm:h-[30px] md:h-[80px] lg:h-[100px] h-[40px]' src={Logo} alt="/" />
    
    <div>
    <p className='sm:text-sm md:text-3xl lg:text-5xl text-xl font-bold text-white pl-[50px] sm:pl-[40px] md:pl-[300px]'>Angani 3CX Analytics</p>
    <p className='text-[8px] sm:text-[8px] md:text-[16px] lg:text-[20px] font-light items-center pl-[50px] sm:pl-[65px] md:pl-[300px] lg:pl-[320px] pt-0 md:pt-2 lg:pt-3'>Visual insights to help you manage your call center</p>
    <div class="h-[2px] w-[55px] bg-orange-600 absolute top-[80px] left-[108px] md:h-1 md:w-[120px] md:top-[140px] md:left-[460px] lg:h-1 lg:w-[125px] lg:top-[145px] lg:left-[462px]"></div>
    </div>
    
    </div>

    <div className='pt-8'>
    <div className='bg-[#8f6f24] h-14 mx-4 rounded-xl md:mx-8 lg:mx-16 md:h-16'>

    <div className='flex'>

    <div className='pt-4 pl-2 md:pt-5 md:pl-4 lg:pl-6'>
    <FaPlay size={8}/>
    </div>
    
    <p className='max-w-[1100px] items-center justify-center pl-2 pt-3 font-light text-[8px] md:text-[16px] md:pl-4 md:max-w-[1400px]'>
      Angani 3CX Analytics is a tool which provides 3CX call centre users with trends, insights and daily performance statistics to help them better manage their call centre.
    </p>

    </div>

    </div>
    </div>

    <div className='pt-8'>
    <div className='flex items-center pl-4 md:pl-16'>
    <FaPlay size={8} class="text-orange-600 "/>
    <p className='font-bold pl-2 text-[12px] md:text-[16px] md:pl-4'>Analytics provided</p>
    </div>
    </div>

    <div className='pl-[80px] md:pl-[90px] pt-4 md:max-w-[800px] lg:pl-[120px] lg:max-w-[750px]'>
    <ul className='list-disc'>
      <li className='text-orange-600'>
      <div className='flex max-w-[250px] md:max-w-[600px] lg:max-w-[800px] text-white'>
      <p className='text-[10px] md:text-[16px] md:pl-2 lg:pl-4'><span className='text-orange-600'>Calls Breakdown</span> by period, type [inbound, outbound] and status [answered, unanswered]</p>
      </div>
      </li>
      <li className='text-orange-600'>
      <div className='flex max-w-[250px] md:max-w-[600px] lg:max-w-[800px] text-white md:pt-4 lg:pt-5'>
      <p className='text-[10px] md:text-[16px] md:pl-2 lg:pl-4'><span className='text-orange-600'>Call frequency</span> of inbound and outbound calls</p>
      </div>
      </li>
      <li className='text-orange-600'>
      <div className='flex max-w-[250px] md:max-w-[600px] lg:max-w-[800px] text-white md:pt-4 lg:pt-5'>
      <p className='text-[10px] md:text-[16px] md:pl-2 lg:pl-4'>Total and average <span className='text-orange-600'>cost of outbound calls</span> by period</p>
      </div>
      </li>
      <li className='text-orange-600'>
      <div className='flex max-w-[250px] md:max-w-[100px] lg:max-w-[1200px] text-white md:pt-4 lg:pt-5'>
      <p className='text-[10px] md:text-[16px] md:pl-2 lg:pl-4'> <span className='text-orange-600'>Agent performance</span> by number of calls, total and average cost, total login time, total talk and ring time</p>
      </div>
      </li>
    </ul>
    </div>

    <div>
    <img src={CEO} alt="/" className='w-[200px] h-[100px] absolute top-[400px] left-[85px] md:w-[350px] md:h-[200px] md:top-[280px] md:left-[850px]'/>
    <img src={Performance} alt="/" className='w-[200px] h-[100px] absolute top-[470px] left-[130px] md:w-[350px] md:h-[200px] md:top-[380px] md:left-[950px]'/>
    </div>

    <div className='flex items-center pl-4 md:pl-16 pt-60 md:pt-12'>
    <FaPlay size={8} class="text-orange-600 "/>
    <p className='font-bold pl-2 text-[12px] md:text-[16px] md:pl-4'>Contact Us</p>
    </div>

    <div className='pt-4 md:flex lg:flex md:pt-6 md:pb-12'>
    <div className='flex items-center pl-[30px] md:pl-[90px] lg:pl-[100px]'>
    <FaPhone size={14} class="text-orange-600 "/>
    <p className='pl-4 text-[10px] md:text-[16px]'>+254 20 7650028</p>
    </div>

    <div className='flex items-center pl-[30px] pt-8 md:pt-0 md:pl-[120px] lg:pl-[100px]'>
    <FaEnvelope size={14} class="text-orange-600 "/>
    <p className='pl-4 text-[10px] md:text-[16px] underline'><a href='mailto:analytics@angani.co' target='_blank' rel='noreferrer'>analytics@angani.co</a> </p>
    </div>

    <div className='flex items-center pl-[30px] pt-8 pb-8 md:pt-0 md:pb-0 md:pl-[120px] lg:pl-[100px]'>
    <FaGlobe size={14} class="text-orange-600 "/>
    <p className='pl-4 text-[10px] md:text-[16px] underline hover:underline text-blue-500'><a href="https://angani.co" target='_blank' rel='noreferrer'>https://angani.co</a></p>
    </div>
    </div>

    

    </div>

    

   
    // <div className='max-w-[1240px] mx-auto text-white max-sm:w-[400px] max-md:w-[800px] max-lg:w-[1400px]'>
    // <div className='flex items-center pt-8 sm:pt-4 md:pt-4 lg:pt-10'>
    // <img className='sm:w-[10px] md:w-[100px] lg:w-[120px] w-[40px] sm:h-[10px] md:h-[100px] lg:h-[120px] h-[40px]' src={Logo} alt="/" />
    // <p className='sm:text-sm md:text-3xl lg:text-5xl text-xl font-bold text-white pl-[70px] sm:pl-[40px] md:pl-[300px]'>Angani 3CX Analytics</p>
    // </div>
      
    // <div className='items-center pl-[85px] sm:pl-[65px] md:pl-[400px] lg:pl-[420px]'>
    // <p className='text-[10px] sm:text-[8px] md:text-[16px] lg:text-[20px] font-light'>Visual insights to help you manage your call center</p>
    // </div>
    
    // {/* <div class="h-1 w-[120px] bg-orange-600 absolute top-[195px] left-[480px]"></div> */}
    // </div>
    
  )
}

export default Navbar
