/* eslint-disable react/jsx-no-undef */
import React from "react";
import Navbar from "./components/Navbar";
// import Details from "./components/Details";


function App() {
  return (
    <div>
      <Navbar />
      {/* <Details /> */}
      {/* <Analytics />
      <Ceo/>
      <Performance/>
      <Footer/> */}
      
    </div>
  );
}

export default App;
